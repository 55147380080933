var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { space } from 'styled-system';
import Icon from '../../common/components/Icon';
var ICON_SIZE = 18;
var Arrow = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transform: rotate(", "deg);\n  ", ";\n"], ["\n  transform: rotate(", "deg);\n  ", ";\n"])), function (_a) {
    var reversed = _a.reversed;
    return (reversed ? 180 : 0);
}, space);
Arrow.defaultProps = {
    name: 'arrowLeft',
    mx: 'sp_4',
    size: ICON_SIZE,
};
export default Arrow;
var templateObject_1;
